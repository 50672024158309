var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"production-2024-10-09-11:54:25"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import '@sentry/nextjs'

import { logError } from 'infrastructure/logger'

window.onerror = (_message, _source, _lineno, _colno, err) => {
	logError(err)
}

window.onunhandledrejection = (evt) => {
	logError(evt.reason)
}
